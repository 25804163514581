import { React, lazy, Suspense, useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux'
import { BrowserRouter as Router, Routes, Route, useNavigate } from 'react-router-dom'


import Login from './pages/auth/Login';
import Register from './pages/auth/Register';


//ROUTES
import Dashboard from './pages/dashboard/Dashboard';
import EventList from './pages/event/EventList';
import EventDetail from './pages/event/EventDetail';
import EventDetailVideo from './pages/event/EventDetailVideo';
import Profile from './pages/profile/Profile';
import MyProfile from './pages/profile/MyProfile';

/// Layout
import Nav from "./jsx/layouts/nav";
import Footer from "./jsx/layouts/Footer";
import ScrollToTop from "./jsx/layouts/ScrollToTop";

import "./vendor/bootstrap-select/dist/css/bootstrap-select.min.css";
import "./css/style.css";

function App() {

  const navigate = useNavigate();

  const isAuthenticated = useSelector((state) => state.auth.user !== null);


  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  console.log("useruser: " + user);


  useEffect(() => {

    if (!isAuthenticated) {
      navigate('/login')
    }

  }, [isAuthenticated, navigate])



  if (isAuthenticated) {
    return (
      <>
        <Suspense fallback={
          <div id="preloader">
            <div className="sk-three-bounce">
              <div className="sk-child sk-bounce1"></div>
              <div className="sk-child sk-bounce2"></div>
              <div className="sk-child sk-bounce3"></div>
            </div>
          </div>
        }
        >
          <div
            id={"main-wrapper"}
            className={"show"}
          >
            <Nav />
            <div className={"content-body"}>
              <div
                className={"container-fluid"}
                style={{ minHeight: window.screen.height - 60 }}
              >
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/EventList" element={<EventList />} />
                  <Route path="/meeting/:eventId" element={<EventDetail />} />
                  <Route path="/EventDetail/:eventId" element={<EventDetail />} />
                  <Route path="/EventDetailVideo/:eventId" element={<EventDetailVideo />} />
                  <Route path="/Profile/:profileId" element={<Profile />} />
                  <Route path="/MyProfile" element={<MyProfile />} />
                  <Route path="/:page" element={<Dashboard />} />
                </Routes>
              </div>
            </div >
            <Footer />
          </div >
          <ScrollToTop />
        </Suspense>
      </>
    );
  } else {
    return (
      <>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/" element={<Login />} />
        </Routes>
      </>
    );
  }



}

export default App;
